  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Noto+Sans+NKo+Unjoined:wght@400;700&display=swap');


*{
font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
font-family: 'Noto Sans NKo Unjoined', sans-serif;
box-sizing: border-box;
margin: 0;
padding: 0;
}
